// stylelint-disable selector-max-compound-selectors
@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';
@import '../commons/animations';

.container {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .header {
        position: relative;
        background: #222;
    }

    .middle {
        position: relative;
        z-index: 1;
        flex-grow: 1;
    }

    .player {
        @extend %fullscreen;
        z-index: 1;
    }

    .overlay {
        @extend %fullscreen;
        z-index: 2;
    }

    .fullscreenButton {
        position: absolute;
        z-index: 10;
        right: 0;
        bottom: 0;
        margin: 0;
        background-color: transparent;
    }

    .start {
        @extend %fullscreen;
        display: flex;
        z-index: 3;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            margin-bottom: 1em;
            padding-bottom: 0;
            font-size: 4rem;
            font-weight: 700;
            text-transform: uppercase;
        }

        .button {
            padding: 10px 30px;
            transition: background 0.2s ease-out, color 0.2s ease-out;
            background-color: transparent;
            background-image: url('../../img/label.svg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% 100%;
            color: #000;
            font-size: $font-size-large;
            font-weight: bold;
            text-transform: uppercase;

            &:hover {
                background-image: url('../../img/label-active.svg');
                color: #000;
            }
        }
    }

    .answers {
        font-size: $font-size-medium;
    }

    .footer {
        position: relative;
        min-height: 2em;
        background: #222;
    }

    .notification {
        position: absolute;
        z-index: 3;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0 20px 20px;
        text-align: center;

        .bubble {
            display: inline-block;
            padding: 20px;
            border: solid 2px #fff;
            border-radius: 30px;
            background: rgba(#333, 0.8);
            color: #fff;
            font-size: $font-size-large;
        }
    }

    .logo {
        width: 360px;
        height: 360px;
        margin: 20px;
    }

    @media #{$mq-medium-screen} {
        //
    }
}
