@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;
    background: $color-black;

    * {
        outline: none;
        user-select: none;
    }

    .participants {
        @extend %fullscreen;
        display: flex;
        z-index: 1;
        flex-direction: row;
        align-items: center;
    }

    .overlay {
        @extend %fullscreen;
        display: flex;
        z-index: 2;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(#000, 0.5);

        .closeButton {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            padding: 20px;
            font-size: $font-size-large;
            font-weight: normal;
        }
    }

    .preview {
        position: absolute;
        z-index: 3;
        right: 10px;
        bottom: 10px;
        width: 200px;
        height: 150px;
        border: solid 2px #fff;
        background: #000;
    }

    .participant {
        position: relative;
        border-right: solid 2px #333;

        &:last-child {
            border-right: 0;
        }
    }

    .video {
        @extend %fullscreen;
        z-index: 1;
    }

    .header,
    .footer {
        display: flex;
        position: absolute;
        z-index: 2;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 20px;
        background: rgba(#000, 0.5);
    }

    .header {
        top: 0;
        left: 0;
        flex-direction: column;
        align-items: flex-start;

        .name {
            margin-bottom: 10px;
            font-size: $font-size-medium;
            font-weight: bold;
        }
    }

    .footer {
        bottom: 0;
        left: 0;
    }

    .settings {
        position: absolute;
        z-index: 9999;
        bottom: 0;
        left: 0;
    }

    &.full {
        .participant {
            @extend %fullscreen;
        }
    }

    &.tile {
        .participant {
            flex-grow: 1;
            max-width: 50%;
            height: 100%;
        }
    }
}
