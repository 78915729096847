@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    font-size: $font-size-medium;

    .padded {
        padding: 0 5px;
    }
}
