@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    margin: 10px;
    padding: 10px;
    background-color: transparent;
    font-size: $font-size-medium;
    pointer-events: auto;
}
