@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        margin-bottom: 20px;
        font-size: $font-size-medium;
        font-weight: normal;
    }

    .notSupported {
        font-size: $font-size-medium;
    }

    .device {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .label {
        width: 8em;
        margin-right: 20px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .select {
        font-size: $font-size-medium;
    }
}
