@font-face {
    font-family: 'Charlevoix';
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/charlevoix/CharlevoixPro-Regular.eot?') format('eot'),
        url('../../fonts/charlevoix/CharlevoixPro-Regular.woff2') format('woff2'),
        url('../../fonts/charlevoix/CharlevoixPro-Regular.woff') format('woff'),
        url('../../fonts/charlevoix/CharlevoixPro-Regular.otf') format('opentype'),
        url('../../fonts/charlevoix/CharlevoixPro-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Charlevoix';
    font-weight: 700;
    font-display: swap;
    src: url('../../fonts/charlevoix/CharlevoixPro-Bold.eot?') format('eot'),
        url('../../fonts/charlevoix/CharlevoixPro-Bold.woff2') format('woff2'),
        url('../../fonts/charlevoix/CharlevoixPro-Bold.woff') format('woff'),
        url('../../fonts/charlevoix/CharlevoixPro-Bold.otf') format('opentype'),
        url('../../fonts/charlevoix/CharlevoixPro-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Charlevoix';
    font-weight: 900;
    font-display: swap;
    src: url('../../fonts/charlevoix/CharlevoixPro-Black.eot?') format('eot'),
        url('../../fonts/charlevoix/CharlevoixPro-Black.woff2') format('woff2'),
        url('../../fonts/charlevoix/CharlevoixPro-Black.woff') format('woff'),
        url('../../fonts/charlevoix/CharlevoixPro-Black.otf') format('opentype'),
        url('../../fonts/charlevoix/CharlevoixPro-Black.ttf') format('truetype');
}
