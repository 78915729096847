// stylelint-disable selector-max-compound-selectors
@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';
@import '../commons/animations';

.container {
    @extend %page;
    width: 100%;
    padding: 40px;
    overflow-y: auto;

    .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .button {
            padding: 10px;
            font-size: 1.2rem;
        }
    }
}
