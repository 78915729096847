@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .inner {
        width: 100%;
        height: 100%;
    }
}
