/**
 * Commons
 */
@import 'commons/variables';
@import 'commons/placeholders';
@import 'commons/mixins';
@import 'commons/fonts';
@import 'commons/medias_queries';
@import 'commons/reset';
@import 'commons/general';
@import 'commons/animations';

html,
body {
    margin: 0;
    font-size: $font-size-normal;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

body {
    background-color: $color-background;
    color: $color-text;
    font-family: $font-text;

    line-height: 1;
    text-rendering: optimizeLegibility;
    font-feature-settings: 'liga', 'liga', 'clig', auto;
    font-kerning: auto;
}

/* stylelint-disable */
html,
body,
#root {
    width: 100%;
    height: 100%;
}

.page {
    width: 100%;
    height: 100%;
}
/* stylelint-enable */

:global(.filmstrip) {
    display: none !important;
    visibility: hidden !important;
}
