@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    text-align: center;

    .question {
        max-width: 20em;
        margin-bottom: 1em;
        padding: 0 20px;
        font-size: $font-size-medium * 1.5;
        font-weight: normal;
        letter-spacing: 0.01em;
        line-height: 1.2;
    }

    .answers {
        display: block;
        width: 100%;
        max-width: 1200px;
        padding: 0 10px;
    }

    .items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: -10px -20px;
    }

    .button {
        display: block;
        width: 100%;
        max-width: 100px;
        margin: 10px 30px;
        padding: 0;
        transition: opacity 0.2s ease-out, filter 0.2s ease-out;
        background-color: transparent;
        color: #000;
        font-size: $font-size-normal * 0.75;
        font-weight: bold;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        white-space: nowrap;

        .shape {
            display: block;
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
        }

        .inner {
            @extend %fullscreen;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

        .label {
            position: relative;
            z-index: 3;
            min-width: 90%;
            padding: 5px 20px;
            transition: background 0.2s ease-out, color 0.2s ease-out;
            background-color: transparent;
            background-image: url('../../img/label.svg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% 100%;
        }

        .circle {
            @extend %fullscreen;
            z-index: 1;
            top: 20%;
            left: 20%;
            width: 60%;
            height: 60%;
            transition: background 0.2s ease-out;
            background-color: transparent;
            background-image: url('../../img/circle.svg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }

        .image {
            @extend %fullscreen;
            z-index: 2;
            top: -10%;
            left: -10%;
            width: 120%;
            height: 120%;
            transition: filter 0.2s ease-out;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }

        &.withImage {
            .inner {
                justify-content: flex-end;
            }

            .label {
                margin-bottom: 20px;
            }

            .circle {
                top: 10%;
                left: 10%;
                width: 80%;
                height: 80%;
            }
        }

        &:hover {
            .label {
                background-image: url('../../img/label-active.svg');
                color: #000;
            }
        }
    }

    .active {
        .label {
            background-image: url('../../img/label-active.svg');
            color: #000;
        }

        .circle {
            background-image: url('../../img/circle-active.svg');
        }

        .image {
            filter: sepia(100%) hue-rotate(100deg);
        }
    }

    .done {
        .button {
            opacity: 0.2;
            filter: grayscale(1);
        }

        .active {
            opacity: 1;
            filter: none;
        }
    }

    @media #{$mq-small-screen} {
        .button {
            max-width: 120px;
        }
    }

    @media #{$mq-medium-screen} {
        .answers {
            max-width: 800px;
        }

        .question {
            font-size: $font-size-large * 1.5;
        }

        .button {
            max-width: 150px;
            font-size: $font-size-normal;
        }
    }

    @media #{$mq-large-screen} {
        .answers {
            max-width: 1200px;
        }

        .question {
            font-size: $font-size-very-large * 1.5;
        }

        .button {
            max-width: 200px;
            font-size: $font-size-medium;
        }
    }
}
