/**
 * Colors
 */
$color-white: #fff;
$color-black: #000;

$color-pink: #d45b9a;
$color-bright-pink: #f3c;
$color-purple: #430194;
$color-bright-purple: #609;
$color-yellow: #ffffd0;
$color-teal: #02abae;
$color-blue: #004292;
$color-turquoise: #00a5ad;
$color-dark-blue: #1b1464;
$color-beige: #ffe7da;
$color-pinkish: #fcc;

$color-background: $color-black;
$color-text: $color-white;
$color-border: $color-pinkish;

/**
 * Fonts
 */
$font-text: Charlevoix, Helvetica, Arial, sans-serif;
$font-title: Charlevoix, Helvetica, Arial, sans-serif;

$font-size-normal: 16px;
$font-size-medium: 1.5rem;
$font-size-large: 2rem;
$font-size-very-large: 2.5rem;

$line-height-normal: 1.25;

/**
 * Screen size
 */
$small-screen: 500px;
$medium-screen: 790px;
$large-screen: 1000px;

$max-page-width: 1080px;

$border: 3px solid $color-border;

$button-transition: box-shadow 0.12s ease-out, transform 0.12s ease-out, color 0.12s ease-out;
$brain-transition: opacity 0.12s ease-out;

/**
 * Media queries
 */
$mq-small-screen: 'only screen and (min-width: #{$small-screen})';
$mq-medium-screen: 'only screen and (min-width: #{$medium-screen})';
$mq-large-screen: 'only screen and (min-width: #{$large-screen})';
$mq-high-screen: 'only screen and (min-width: #{$large-screen}) and (min-height: 800px)';
