@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    position: relative;

    .button {
        @extend %reset-button;
        @extend %fullscreen;
        display: flex;
        z-index: 2;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(#000, 0.8);

        .icon {
            position: relative;
            width: 20%;
            height: 20%;
            animation-name: bounce;
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }
    }

    .video {
        @extend %fullscreen;
        z-index: 1;
    }
}

@keyframes bounce {
    0% {
        transform: scale(1, 1);
        color: #fff;
    }

    50% {
        transform: scale(1.1, 1.1);
        color: #ff9;
    }

    100% {
        transform: scale(1, 1);
        color: #fff;
    }
}
