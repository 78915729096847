@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 20px;

    .question {
        max-width: 80%;
        font-size: $font-size-medium;
        text-align: left;
    }
}
