@import '../commons/variables';
@import '../commons/placeholders';
@import '../commons/mixins';

.container {
    @extend %reset-button;
    display: inline-block;

    &.isDisabled {
        cursor: default;
    }
}
